var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--dapp-submission" },
    [
      _c("the-layout-header", {
        attrs: {
          title: _vm.$t("dappsSubmission.banner-submit.dapp-submission-form"),
        },
      }),
      _c(
        "v-form",
        { ref: "form", on: { input: _vm.progress } },
        [
          _c(
            "v-container",
            { staticClass: "my-10" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mb-8",
                          staticStyle: { "max-width": "500px" },
                        },
                        [
                          _c("div", { staticClass: "mew-heading-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t(
                                      "dappsSubmission.tell-us-about-your-dapp"
                                    ),
                                    "dappsSubmission.tell-us-about-your-dapp"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "mt-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.provide-text"),
                                    "dappsSubmission.provide-text"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.name"
                                  ),
                                  "dappsSubmission.about-your-dapp.name"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("mew-input", {
                        attrs: {
                          rules: [_vm.rules.noEmptyString],
                          outlined: "",
                        },
                        model: {
                          value: _vm.form.dappName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dappName", $$v)
                          },
                          expression: "form.dappName",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.category"
                                  ),
                                  "dappsSubmission.about-your-dapp.category"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("v-select", {
                        attrs: {
                          rules: [_vm.rules.noNull],
                          items: _vm.itemsCategory,
                          placeholder: "Select a category",
                          outlined: "",
                        },
                        model: {
                          value: _vm.form.category,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "category", $$v)
                          },
                          expression: "form.category",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.tags-title"
                                  ),
                                  "dappsSubmission.about-your-dapp.tags-title"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.tags"
                                  ),
                                  "dappsSubmission.about-your-dapp.tags"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t(
                                      "dappsSubmission.about-your-dapp.suggested-tags"
                                    ),
                                    "dappsSubmission.about-your-dapp.suggested-tags"
                                  )
                                ) +
                                " "
                            ),
                            _vm._l(_vm.tagsList, function (tag, tagKey) {
                              return _c(
                                "v-chip",
                                {
                                  key: tagKey,
                                  staticClass: "ml-1 cursor--pointer",
                                  attrs: { "x-small": "", val: tag },
                                  on: { click: _vm.fillTags },
                                },
                                [_vm._v(" " + _vm._s(tag) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c("v-combobox", {
                        attrs: {
                          rules: [_vm.rules.noEmptyArray],
                          multiple: "",
                          chips: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.form.tags,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tags", $$v)
                          },
                          expression: "form.tags",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.description"
                                  ),
                                  "dappsSubmission.about-your-dapp.description"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.description-placeholder"
                                  ),
                                  "dappsSubmission.about-your-dapp.description-placeholder"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          counter: "",
                          rules: [_vm.rules.noEmptyString],
                          maxlength: "800",
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.contract-address-title"
                                  ),
                                  "dappsSubmission.about-your-dapp.contract-address-title"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("mew-input", {
                        attrs: {
                          outlined: "",
                          rules: [
                            _vm.rules.noEmptyString,
                            _vm.rules.requireValidEthAddress,
                          ],
                        },
                        model: {
                          value: _vm.form.contractAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contractAddress", $$v)
                          },
                          expression: "form.contractAddress",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.dapp-website"
                                  ),
                                  "dappsSubmission.about-your-dapp.dapp-website"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("mew-input", {
                        attrs: { outlined: "" },
                        model: {
                          value: _vm.form.dappWebsite,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dappWebsite", $$v)
                          },
                          expression: "form.dappWebsite",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.dapp-us"
                                  ),
                                  "dappsSubmission.about-your-dapp.dapp-us"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "border" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0",
                              attrs: { column: "", rules: [_vm.rules.noNull] },
                              model: {
                                value: _vm.form.usMarket,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "usMarket", $$v)
                                },
                                expression: "form.usMarket",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: "yes" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: "no" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.dapp-status"
                                  ),
                                  "dappsSubmission.about-your-dapp.dapp-status"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "border" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0",
                              attrs: { rules: [_vm.rules.noNull], column: "" },
                              model: {
                                value: _vm.form.dappStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dappStatus", $$v)
                                },
                                expression: "form.dappStatus",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Live", value: "Live" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Beta", value: "Beta" },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Prototype",
                                  value: "Prototype",
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Work in progress",
                                  value: "Work in progress",
                                },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Concept", value: "Concept" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Stealth", value: "Stealth" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.contract-audit-title"
                                  ),
                                  "dappsSubmission.about-your-dapp.contract-audit-title"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.contract-audit"
                                  ),
                                  "dappsSubmission.about-your-dapp.contract-audit"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "border" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0",
                              attrs: { rules: [_vm.rules.noNull], column: "" },
                              model: {
                                value: _vm.form.contractAudit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "contractAudit", $$v)
                                },
                                expression: "form.contractAudit",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "None in process",
                                  value: "None in process",
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "In process",
                                  value: "In process",
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Completed",
                                  value: "Completed",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.mock-flow-title"
                                  ),
                                  "dappsSubmission.about-your-dapp.mock-flow-title"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.mock-requirements"
                                  ),
                                  "dappsSubmission.about-your-dapp.mock-requirements"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", [
                        _vm._v(" " + _vm._s(_vm.form.mockFlowFile) + " "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "border" },
                        [
                          _c("v-file-input", {
                            attrs: {
                              rules: [_vm.rules.noNull],
                              accept: "image/jpeg, .pdf",
                              label: "JPEG, PDF (> 5MB)",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.imgPreview($event, "imgMockFlow")
                              },
                            },
                            model: {
                              value: _vm.form.mockFlowFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mockFlowFile", $$v)
                              },
                              expression: "form.mockFlowFile",
                            },
                          }),
                          _vm.imgMockFlow
                            ? _c("img", {
                                staticClass: "preview",
                                attrs: {
                                  src: _vm.imgMockFlow,
                                  alt: "Mock flow",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.dapp-icon-title"
                                  ),
                                  "dappsSubmission.about-your-dapp.dapp-icon-title"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.dapp-icon"
                                  ),
                                  "dappsSubmission.about-your-dapp.dapp-icon"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "border" },
                        [
                          _c("v-file-input", {
                            attrs: {
                              rules: [_vm.rules.noNull],
                              accept: "image/jpeg, image/png",
                              label: "JPEG, PNG",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.imgPreview($event, "imgDappIcon")
                              },
                            },
                            model: {
                              value: _vm.form.dappIconFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dappIconFile", $$v)
                              },
                              expression: "form.dappIconFile",
                            },
                          }),
                          _vm.imgDappIcon
                            ? _c("img", {
                                staticClass: "preview",
                                attrs: {
                                  src: _vm.imgDappIcon,
                                  alt: "Mock flow",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.banner-title"
                                  ),
                                  "dappsSubmission.about-your-dapp.banner-title"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-dapp.banner"
                                  ),
                                  "dappsSubmission.about-your-dapp.banner"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "border" },
                        [
                          _c("v-file-input", {
                            attrs: {
                              rules: [_vm.rules.noNull],
                              accept: "image/jpeg, image/png",
                              label: "JPEG, PNG",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.imgPreview($event, "imgBanner")
                              },
                            },
                            model: {
                              value: _vm.form.bannerFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "bannerFile", $$v)
                              },
                              expression: "form.bannerFile",
                            },
                          }),
                          _vm.imgBanner
                            ? _c("img", {
                                staticClass: "preview",
                                attrs: { src: _vm.imgBanner, alt: "Mock flow" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.authors-title"
                                  ),
                                  "dappsSubmission.about-your-team.authors-title"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.authors"
                                  ),
                                  "dappsSubmission.about-your-team.authors"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("mew-input", {
                        attrs: {
                          rules: [_vm.rules.noEmptyString],
                          placeholder: _vm.$t(
                            "dappsSubmission.about-your-team.author-placeholder"
                          ),
                        },
                        model: {
                          value: _vm.form.authors,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "authors", $$v)
                          },
                          expression: "form.authors",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.full-name"
                                  ),
                                  "dappsSubmission.about-your-team.full-name"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("mew-input", {
                        attrs: { rules: [_vm.rules.noEmptyString] },
                        model: {
                          value: _vm.form.fullName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fullName", $$v)
                          },
                          expression: "form.fullName",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.email"
                                  ),
                                  "dappsSubmission.about-your-team.email"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("mew-input", {
                        attrs: {
                          rules: [_vm.rules.noEmptyString, _vm.rules.email],
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.social-links"
                                  ),
                                  "dappsSubmission.about-your-team.social-links"
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mew-label" }, [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.required"),
                                    "dappsSubmission.required"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.provide-social-link"
                                  ),
                                  "dappsSubmission.about-your-team.provide-social-link"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "social-media mt-1 d-flex align-center",
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "px-1",
                                attrs: { small: "", color: "basic" },
                              },
                              [_vm._v("mdi-facebook")]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "px-1",
                                attrs: { small: "", color: "basic" },
                              },
                              [_vm._v("mdi-twitter")]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "px-1",
                                attrs: { small: "", color: "basic" },
                              },
                              [_vm._v("mdi-linkedin")]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "px-1",
                                attrs: { small: "", color: "basic" },
                              },
                              [_vm._v("mdi-reddit")]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "px-1",
                                attrs: { small: "", color: "basic" },
                              },
                              [_vm._v("mdi-medium")]
                            ),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/socials/svg/github.svg"),
                                alt: "Github",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("v-combobox", {
                        attrs: {
                          rules: [_vm.rules.noEmptyArray],
                          multiple: "",
                          chips: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.form.socialLinks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "socialLinks", $$v)
                          },
                          expression: "form.socialLinks",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.company-website"
                                  ),
                                  "dappsSubmission.about-your-team.company-website"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("mew-input", {
                        model: {
                          value: _vm.form.companyWebsite,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "companyWebsite", $$v)
                          },
                          expression: "form.companyWebsite",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.license"
                                  ),
                                  "dappsSubmission.about-your-team.license"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("mew-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "dappsSubmission.about-your-team.license-placeholder"
                          ),
                        },
                        model: {
                          value: _vm.form.license,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "license", $$v)
                          },
                          expression: "form.license",
                        },
                      }),
                      _c("div", { staticClass: "section-title" }, [
                        _c("div", { staticClass: "mew-heading-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.additional-notes"
                                  ),
                                  "dappsSubmission.about-your-team.additional-notes"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t(
                                    "dappsSubmission.about-your-team.notes-placeholder"
                                  ),
                                  "dappsSubmission.about-your-team.notes-placeholder"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("v-textarea", {
                        attrs: { outlined: "", counter: "", maxlength: "300" },
                        model: {
                          value: _vm.form.additionalNotes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "additionalNotes", $$v)
                          },
                          expression: "form.additionalNotes",
                        },
                      }),
                      _c("mew-button", {
                        staticClass: "d-block mx-auto mt-10",
                        attrs: {
                          disabled:
                            _vm.validRequiredFormsRate !== 100 ? true : false,
                          title: _vm.$t("dappsSubmission.submit"),
                          "btn-size": "xlarge",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.submitForm.apply(null, arguments)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.validRequiredFormsRate !== 100,
                              expression: "validRequiredFormsRate !== 100",
                            },
                          ],
                          staticClass:
                            "text-center mx-auto mt-4 mb-4 redPrimary--text",
                          staticStyle: { "max-width": "300px" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "dappsSubmission.fill-out-required-fields"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                    _c(
                      "div",
                      { staticStyle: { position: "sticky", top: "0" } },
                      [
                        _c(
                          "div",
                          { staticClass: "greyLight pa-6 d-none d-md-block" },
                          [
                            _c("div", { staticClass: "mew-heading-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("lokalise")(
                                      _vm.$t("dappsSubmission.progress"),
                                      "dappsSubmission.progress"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("v-progress-linear", {
                              staticClass: "mt-5",
                              attrs: { color: "greenPrimary", height: "25" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var value = ref.value
                                    return [
                                      _c("strong", [
                                        _vm._v(_vm._s(Math.ceil(value)) + "%"),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.validRequiredFormsRate,
                                callback: function ($$v) {
                                  _vm.validRequiredFormsRate = $$v
                                },
                                expression: "validRequiredFormsRate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "greyLight pa-6 mt-6" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between",
                            },
                            [
                              _c("div", { staticClass: "mew-heading-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("lokalise")(
                                        _vm.$t("dappsSubmission.sotd.title"),
                                        "dappsSubmission.sotd.title"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("img", {
                                staticClass: "ml-3",
                                staticStyle: { "border-radius": "50%" },
                                attrs: {
                                  src: require("@/assets/images/icons/icon-sotd.png"),
                                  alt: "stateofthedapps.com",
                                  height: "50",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-5" },
                            [
                              _c(
                                "i18n",
                                {
                                  attrs: {
                                    tag: "div",
                                    path: "dappsSubmission.sotd.info",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        slot: "url",
                                        href: "https://stateofthedapps.com",
                                        target: "_blank",
                                      },
                                      slot: "url",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("lokalise")(
                                              _vm.$t(
                                                "dappsSubmission.sotd.url"
                                              ),
                                              "dappsSubmission.sotd.url"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "greyLight pa-6 mt-6" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between",
                            },
                            [
                              _c("div", { staticClass: "mew-heading-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("lokalise")(
                                        _vm.$t(
                                          "dappsSubmission.mew-support.title"
                                        ),
                                        "dappsSubmission.mew-support.title"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("img", {
                                staticClass: "ml-3",
                                attrs: {
                                  src: require("@/assets/images/icons/icon-message2-mew.svg"),
                                  alt: "MEW support",
                                  height: "50",
                                },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "mt-3" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("dappsSubmission.mew-support.info"),
                                    "dappsSubmission.mew-support.info"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "mailto:support@xinfin.org",
                                target: "_blank",
                              },
                            },
                            [
                              _c("mew-button", {
                                staticClass: "mt-3",
                                attrs: {
                                  title: _vm.$t(
                                    "dappsSubmission.contact-support"
                                  ),
                                  "has-full-width": "",
                                  "btn-style": "outline",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.formSubmissionSuccessful,
            callback: function ($$v) {
              _vm.formSubmissionSuccessful = $$v
            },
            expression: "formSubmissionSuccessful",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pa-5 white" },
            [
              _c("div", { staticClass: "mew-heading-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("lokalise")(
                        _vm.$t("dappsSubmission.successful"),
                        "dappsSubmission.successful"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mt-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("lokalise")(
                        _vm.$t("dappsSubmission.congrats-msg"),
                        "dappsSubmission.congrats-msg"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("mew-button", {
                staticClass: "ml-auto d-block mt-5",
                attrs: { title: _vm.$t("dappsSubmission.okay") },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "Home" })
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.formSubmissionFailed,
            callback: function ($$v) {
              _vm.formSubmissionFailed = $$v
            },
            expression: "formSubmissionFailed",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pa-5 white" },
            [
              _c("div", { staticClass: "mew-heading-2 redPrimary--text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("lokalise")(
                        _vm.$t("dappsSubmission.failed"),
                        "dappsSubmission.failed"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mt-3 redPrimary--text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("lokalise")(
                        _vm.$t("dappsSubmission.try-again"),
                        "dappsSubmission.try-again"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("mew-button", {
                staticClass: "ml-auto d-block mt-5",
                attrs: { title: _vm.$t("dappsSubmission.okay") },
                nativeOn: {
                  click: function ($event) {
                    _vm.formSubmissionFailed = false
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }